import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import styles from 'app/components/action/Action.module.scss'
import PdfViewer from 'app/components/action/pdfViewer'
import ReviewAction from 'app/components/review/action'
import CancelReview from 'app/components/review/cancelReview'
import Reviewers from 'app/components/review/reviewers'
import SendToReview from 'app/components/review/sendToReview'
import { WppActionButton, WppButton, WppIconChatMessage } from 'buildingBlocks'
import { APP_ACTIONS, QUESTIONNAIRE_STATUS, TOAST_MESSAGE_TYPES } from 'config/enums'
import { RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import FormFieldHelper from 'utils/formField/FormFieldHelper'

const formFieldHelper = new FormFieldHelper()

interface IActionProps {
  /** Handle app Submit/Save Progress/Cancel event*/
  handleAppAction: (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES) => void,
    displayMessage?: boolean,
  ) => void
  /** Enable/disable comment mode*/
  handleCommentMode: () => void
}

/**
 * App action
 * @param {object} props
 * @param {Function} props.handleCommentMode
 * @param {Function} props.handleAppAction
 */
const Action: React.FC<IActionProps> = ({ handleAppAction, handleCommentMode }: IActionProps): React.ReactElement => {
  const { app, enableCommentMode, activeStep } = useSelector<RootState, IQuestionnaireState>(
    (state: RootState) => state.questionnaireState,
  )
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  const fieldsData = formFieldHelper.getFormFieldsObject(app!.categories)
  const isAppEditor = app!.isAppEditor && app!.isProjectMember
  const isCommentMode =
    !_.isEqual(questionnaire.approval?.status, QUESTIONNAIRE_STATUS.SUCCEEDED) && app!.isProjectMember

  return (
    <>
      {_.isEqual(app!.appName, 'Idea Brief') && (
        <CopyToClipboard
          text={`Brand: Coca-Cola Campaign for FIFA Women's World Cup 2027\nHosting countries: US and Mexico\nInsights: ${fieldsData['the insights']}\nAudiences: Players, National Fans, Superstar Fans, Coerced Companions, Extended Family, Nuclear Family, First Timers, City Citizens, Party Goers, Young Culturalist Couples, Weekend Socialites, Hall Passers, Sports Fans, Patriots\nSuggest ideas for each of the activation types: digital, live, product and retail activations has context menu`}
        >
          <WppButton id="btn-copy-campaign-details" className={styles.btnNextSubmit}>
            <Trans>app.button.copyCampaignDetails</Trans>
          </WppButton>
        </CopyToClipboard>
      )}
      <PdfViewer app={app!} handleAppAction={handleAppAction} />
      {isCommentMode && (
        <WppActionButton
          className={classNames({ [styles.enableCommentMode]: enableCommentMode })}
          id="btn-comment-mode"
          variant="secondary"
          onClick={handleCommentMode}
        >
          <WppIconChatMessage color="var(--wpp-brand-color)" />
        </WppActionButton>
      )}
      <Reviewers />
      <CancelReview />
      {questionnaire.approval && <ReviewAction />}
      {!_.isEqual(app!.categories.length, activeStep) && isAppEditor && (
        <WppButton
          id="btn-save-progress"
          data-testid="btn-save-progress"
          onClick={() => handleAppAction(APP_ACTIONS.SAVE_PROGRESS, () => {})}
          className={classNames(styles.btnSaveProgress)}
          size="s"
        >
          <Trans>app.button.saveProgress</Trans>
        </WppButton>
      )}
      <SendToReview />
      {_.isEqual(app!.categories.length, activeStep) && isAppEditor && (
        <WppButton
          id="btn-submit"
          data-testid="btn-submit"
          onClick={() => handleAppAction(APP_ACTIONS.SAVE_AND_EXIT, () => {})}
          className={styles.btnNextSubmit}
          size="s"
        >
          <Trans>app.button.saveAndExit</Trans>
        </WppButton>
      )}
    </>
  )
}

export default Action
