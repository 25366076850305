import React from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ReviewersList from 'app/components/review/reviewers/reviewersList'
import { WppButton } from 'buildingBlocks'
import { AppDispatch, RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import { setViewStatusModal } from 'store/reducers/reviewSlice'

/**
 * Reviewers component
 */
const Reviewers: React.FC = (): React.ReactElement => {
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const dispatch = useDispatch<AppDispatch>()

  if (!questionnaire.approval) return <></>

  return (
    <>
      <WppButton
        id="btn-view-status"
        data-testid="btn-view-status"
        onClick={() => dispatch(setViewStatusModal(true))}
        size="s"
      >
        <Trans>app.button.viewStatus</Trans>
      </WppButton>
      <ReviewersList />
    </>
  )
}

export default Reviewers
