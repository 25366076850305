import { TFunction } from 'i18next'
import moment from 'moment'

import ReviewerStatus from 'app/components/review/reviewers/reviewerStatus'
import { UserAvatar } from 'buildingBlocks'
import { DATE_TIME_FORMAT } from 'config/constants'
import IReviewerInfo from 'interfaces/review/IReviewerInfo'
import ITableHeader from 'interfaces/table/ITableHeader'
import ITableRow from 'interfaces/table/ITableRow'

export const getColumnData = (t: TFunction): ITableHeader[] => {
  return [
    {
      id: 'name',
      name: t('review.reviewersList.columnData.name'),
      minWidth: 300,
      enableOrderBy: true,
    },
    {
      id: 'date',
      name: t('review.reviewersList.columnData.date'),
      minWidth: 200,
      enableOrderBy: true,
    },
    {
      id: 'details',
      name: t('review.reviewersList.columnData.details'),
      enableOrderBy: true,
    },
    {
      id: 'status',
      name: t('review.reviewersList.columnData.status'),
      minWidth: 140,
      enableOrderBy: true,
    },
  ]
}

export const getRowData = (reviewerData: IReviewerInfo[], requestedAt: string = ''): ITableRow[] => {
  const tableRows: ITableRow[] = reviewerData.map(
    ({
      reviewerFirstname,
      reviewerLastname,
      reviewerEmail,
      reviewerAvatarUrl,
      completedAt,
      responseReason,
      responseType,
    }: IReviewerInfo) => {
      const date = completedAt ?? requestedAt
      return {
        name: {
          component: (
            <UserAvatar
              avatarUrl={reviewerAvatarUrl}
              email={reviewerEmail}
              firstName={reviewerFirstname}
              lastName={reviewerLastname}
              avatarSize="s"
            />
          ),
          text: `${reviewerFirstname} ${reviewerLastname}`,
        },
        date: {
          text: date ? moment.utc(date).format(DATE_TIME_FORMAT) : '',
        },
        details: {
          text: responseReason,
        },
        status: {
          component: <ReviewerStatus status={responseType} />,
          text: responseType,
        },
      }
    },
  )
  return tableRows
}
