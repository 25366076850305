import React from 'react'
import { useSelector } from 'react-redux'

import styles from 'app/components/header/Header.module.scss'
import Status from 'app/components/review/status'
import { WppDivider, WppGrid, WppTypography } from 'buildingBlocks'
import { RootState } from 'store'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'

interface IHeaderProps {
  /** App action component */
  appActionComponent: React.ReactNode
}

/**
 * App header component
 * @param {object} props
 * @param {React.ReactNode} props.appActionComponent
 */
const Header: React.FC<IHeaderProps> = ({ appActionComponent }: IHeaderProps): React.ReactElement => {
  const { app } = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)

  if (!app) return <></>

  return (
    <div className={styles.headerContainer}>
      <WppGrid container>
        <WppGrid item all={24} className={styles.headerItem}>
          <div className={styles.header}>
            <WppTypography tag="h3" type="m-strong">
              {app.appName}
            </WppTypography>
            <Status />
          </div>
          <div>{appActionComponent}</div>
        </WppGrid>
      </WppGrid>
      <WppDivider className={styles.divider} />
    </div>
  )
}

export default Header
