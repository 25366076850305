import { useOs } from '@wppopen/react'
import _ from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import styles from 'app/components/review/approve/approveModal/ApproveModal.module.scss'
import { WppButton, WppModal, WppTooltip } from 'buildingBlocks'
import { PROJECT_ROLES, QUESTIONNAIRE_STATUS } from 'config/enums'
import IProjectMember from 'interfaces/project/IProjectMember'
import { AppDispatch, RootState } from 'store'
import IAppContextState from 'store/interfaces/IAppContextState'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import IReviewState from 'store/interfaces/IReviewState'
import ReviewHelper from 'utils/review/ReviewHelper'

/**
 * Cancel Review component
 */
const CancelReview: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const appContext = useSelector<RootState, IAppContextState>((state: RootState) => state.appContext)
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const { reviewers } = useSelector<RootState, IReviewState>((state: RootState) => state.reviewState)
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { osApi } = useOs()
  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    const member: IProjectMember | undefined = reviewers.find((member: IProjectMember) =>
      _.isEqual(_.toLower(member.email), _.toLower(appContext.userEmail)),
    )

    if (member && _.isEqual(member.role, PROJECT_ROLES.OWNER)) {
      setAuthorized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.userEmail])

  if (!questionnaire.approval) return <></>

  if (!(_.isEqual(questionnaire.approval.status, QUESTIONNAIRE_STATUS.PENDING) && authorized)) {
    return <></>
  }

  const handleModal = () => setOpen((prevState: boolean) => !prevState)

  const handleCancelReview = () => {
    const projectQuestionnaireId = questionnaire.projectQuestionnaireId!

    ReviewHelper.handleCancelReview({
      accessToken: osApi.getAccessToken(),
      dispatch,
      projectQuestionnaireId,
      approval: questionnaire.approval,
      questionnaireState,
      tenantId: appContext.tenantId,
      appInstanceId: appContext.appInstanceId,
      t,
      appContext,
    })
  }

  return (
    <Fragment>
      <WppTooltip
        text={t('review.cancelReview.unauthorized')}
        component={
          <WppButton
            id="btn-cancel-review"
            variant="destructive"
            disabled={!authorized}
            data-testid="btn-cancel-review"
            onClick={handleModal}
            size="s"
          >
            <Trans>app.button.cancelReview</Trans>
          </WppButton>
        }
        showTooltip={!authorized}
      />
      {createPortal(
        <WppModal open={open}>
          <h3 slot="header">
            <Trans>review.cancelReview.title</Trans>
          </h3>
          <p slot="body">
            <Trans>review.cancelReview.body</Trans>
          </p>
          <div slot="actions" className={styles.actions}>
            <WppButton size="s" id="btn-cancel" data-testid="btn-cancel" variant="secondary" onClick={handleModal}>
              <Trans>app.button.no</Trans>
            </WppButton>
            <WppButton
              variant="destructive"
              id="btn-continue"
              data-testid="btn-continue"
              size="s"
              onClick={() => {
                handleCancelReview()
                handleModal()
              }}
            >
              <Trans>app.button.yes</Trans>
            </WppButton>
          </div>
        </WppModal>,
        document.body,
      )}
    </Fragment>
  )
}

export default CancelReview
