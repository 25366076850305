// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bgho3{display:flex;align-items:center;gap:.625rem}.E4M9R .XqAJx{margin-top:8px;max-height:80vh;overflow:auto;padding-right:10px;padding-bottom:30px}.E4M9R .L3aPZ{max-height:80vh;overflow:auto;padding-right:10px;padding-bottom:30px}`, "",{"version":3,"sources":["webpack://./src/pages/questionnaire/Questionnaire.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,WAAA,CAIA,cACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,kBAAA,CACA,mBAAA,CAEF,cACE,eAAA,CACA,aAAA,CACA,kBAAA,CACA,mBAAA","sourcesContent":[".actions {\n  display: flex;\n  align-items: center;\n  gap: 0.625rem;\n}\n\n.layout {\n  .layoutCategories {\n    margin-top: 8px;\n    max-height: 80vh;\n    overflow: auto;\n    padding-right: 10px;\n    padding-bottom: 30px;\n  }\n  .formContainer {\n    max-height: 80vh;\n    overflow: auto;\n    padding-right: 10px;\n    padding-bottom: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `Bgho3`,
	"layout": `E4M9R`,
	"layoutCategories": `XqAJx`,
	"formContainer": `L3aPZ`
};
export default ___CSS_LOADER_EXPORT___;
