// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bGtoq::part(button){min-width:90px}.m2EuG::part(button){color:var(--wpp-button-primary-bg-color, var(--wpp-brand-color));background-color:rgba(0,0,0,0);padding:0;margin:var(--button-secondary-padding-m)}.ZtJCU{opacity:.5}.d7AMT::part(button){background-color:var(--wpp-grey-color-400)}`, "",{"version":3,"sources":["webpack://./src/app/components/action/Action.module.scss"],"names":[],"mappings":"AACE,qBACE,cAAA,CAKF,qBACE,gEAAA,CACA,8BAAA,CACA,SAAA,CACA,wCAAA,CAGJ,OACE,UAAA,CAIA,qBACE,0CAAA","sourcesContent":[".btnNextSubmit {\n  &::part(button) {\n    min-width: 90px;\n  }\n}\n\n.btnSaveProgress {\n  &::part(button) {\n    color: var(--wpp-button-primary-bg-color, var(--wpp-brand-color));\n    background-color: transparent;\n    padding: 0;\n    margin: var(--button-secondary-padding-m);\n  }\n}\n.disabled {\n  opacity: 0.5;\n}\n\n.enableCommentMode {\n  &::part(button) {\n    background-color: var(--wpp-grey-color-400);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnNextSubmit": `bGtoq`,
	"btnSaveProgress": `m2EuG`,
	"disabled": `ZtJCU`,
	"enableCommentMode": `d7AMT`
};
export default ___CSS_LOADER_EXPORT___;
